.swiper-pagination-customs.swiper-pagination-customs-active {
    background: #FFFFFF;
    opacity: 1;
}
  
.swiper-pagination-customs {
    background: rgba(0,0,0,0.5);
    width: 59px;
    height: 4px;
    opacity: 1;
    margin: 0 2px;
    cursor: pointer;
}

.swiper-pagination-customs:first-child {
    border-radius: 2px 0 0 2px;
}

.swiper-pagination-customs:last-child {
    border-radius: 0 2px 2px 0;
}

.swiper-pagination-custom {
    padding: 0 7px;
    width: calc(100% - 14px);
    bottom: inherit;
    top: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.swiper-button-disabled {
    display: none !important;
}
